define('hooks',['jquery', 'app/util', 'skrollr', 'wow'], function ($, util, skrollr, wow) {
    var methods = {
        onDocumentReady: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - document ready.')
            //>>excludeEnd("production");

            $.preloadImages = function () {
                for (var i = 0; i < arguments.length; i++) {
                    $("<img />").attr("src", arguments[i]);
                }
            }
            $.preloadImages("img/home-top-card-img.png", "img/parallax/finalistTopCard.jpg", "img/finalists-logo.png");
        },
        onAppInitialized: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - app initialized.')
            //>>excludeEnd("production");


            //edge case - to enable wow animations if 404 page first page to load
            $(window).hashchange(function (e) {
                e.preventDefault(); //default behaviour of the browsers, to scroll down to the section with the matching id
                if (window.location.hash.replace('#', '') == '404') {
                    var wow = new WOW(
                        {
                            mobile: true,
                            live: true
                        });
                    wow.init();
                }
            });

            // content loader classes that require animations

            // // homepage top card animation
            $('body').livequery('.custom-loader-element.load-finished', function () {

                if ($(window).width() > 768 && skrollr.get() !== undefined) {
                    setTimeout(function () {
                        //refresh skrollr when reloading the inner page anew - element topCard-bottom
                        skrollr.get().refresh($('.topCard-border-bottom[data-pxscroll]'));
                        //refresh skrollr when reloading the inner page anew - content elements
                        skrollr.get().refresh($('.layout-bodyContent[data-pxscroll-unroll]'));
                        skrollr.get().refresh($('.container[data-pxscroll-unroll]'));
                    }, 1000);
                }

                // home aboutCard
                $('.fetch_home_about_card p').each(function (index) {
                    $(this).addClass('wow fadeInUp').attr({
                        "data-wow-duration": "1s",
                        "data-wow-delay": "1." + index + "0s"
                    });
                });

                // home process
                $('.fetch_home_process_card').each(function () {
                    $(this).find('h2').addClass('wow fadeInUp').attr({"data-wow-delay": ".5s"});
                    $(this).find('p').addClass('wow fadeInUp').attr({"data-wow-delay": "1s"});
                });

                // home faq
                $('.fetch_home_faq_card').each(function () {
                    $(this).find('h2').addClass('wow fadeInUp').attr({"data-wow-delay": ".5s"});
                    $(this).find('p').addClass('wow fadeInUp').attr({"data-wow-delay": "1s"});
                });

            });

            setTimeout(function () {
                // interior topcards
                $('body').livequery('.pageContainer.active', function () {
                    $(this).find('.top-card .fixedBg').addClass('load-fixedBg-animation');
                });
            }, 1000);

            // init carousel
            $('.carousel').carousel();

            //keeping proper dimensions for the .page-ink-layer on windows resize - landscape or portrait
            var frameProportion = 1.78, // this is the proportion of a single frame from the png - width/height
                frames = 25, // number of frames
                inkElement = $('.side-menu-ink-layer'),
                windowWidth,
                windowHeight,
                inkLayerWidth,
                inkLayerHeight;

            $(window).on('resize', function () {
                windowWidth = $(window).width();
                windowHeight = $(window).height();

                if (windowWidth / windowHeight > frameProportion) {
                    inkLayerWidth = windowWidth + 20;
                    inkLayerHeight = inkLayerWidth / frameProportion;
                } else {
                    inkLayerHeight = windowHeight + 20;
                    inkLayerWidth = inkLayerHeight * frameProportion;
                }
                ;

                inkElement.css({
                    'width': inkLayerWidth * frames + 'px',
                    'height': inkLayerHeight + 'px',
                });

                /* on resize reinit scrollbar on judges detail info */

                if ($('#judgesDetail .scroll-pane').length) {
                    var api = $('#judgesDetail .scroll-pane').jScrollPane().data('jsp');
                    api.reinitialise();
                }
            });

            //controlling share button actions
            $('.socialExpand-wrapper').on('click', function () {
                var $self = $(this);

                $self.addClass('selected');
            });

            $('.socialExpand-wrapper').on('mouseleave', function () {
                var $self = $(this);

                if ($self.hasClass('selected')) {
                    $self.removeClass('selected');
                }
            });

            $('.event-rsvp .close').click(function(){
                $('.event-rsvp').addClass('shrink');
            });

            // finalist scroll
            var lastScrollTop = 0;
            $('body').livequery('.finalistPageWrap.active', $.proxy(function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(150, util.minScrollSpeed($.proxy(function (event) {

                    var windowHeight = $(window).height();
                    var docHeight = $(document).height();
                    var scrollTop = $(window).scrollTop();
                    var scrollBottom = scrollTop + windowHeight;
                    var finalistFraction = docHeight / 5;

                    if (scrollTop > lastScrollTop) {
                        // scroll down
                        if (scrollTop > windowHeight) {

                            if (scrollBottom > (finalistFraction)) {
                                $('.finalist-bg-container__card--one').removeClass('fadeIn').addClass('fadeOut');
                            }
                            if (scrollBottom > (finalistFraction * 2)) {
                                $('.finalist-bg-container__card--two').removeClass('fadeIn').addClass('fadeOut');
                            }
                            if (scrollBottom > (finalistFraction * 3)) {
                                $('.finalist-bg-container__card--three').removeClass('fadeIn').addClass('fadeOut');
                            }
                            if (scrollBottom > (finalistFraction * 4)) {
                                $('.finalist-bg-container__card--four').removeClass('fadeIn').addClass('fadeOut');
                            }
                        }
                    } else {
                        // scroll up
                        if (scrollBottom < (finalistFraction)) {
                            $('.finalist-bg-container__card--one').removeClass('fadeOut').addClass('fadeIn');
                        }
                        if (scrollBottom < (finalistFraction * 2)) {
                            $('.finalist-bg-container__card--two').removeClass('fadeOut').addClass('fadeIn');
                        }
                        if (scrollBottom < (finalistFraction * 3)) {
                            $('.finalist-bg-container__card--three').removeClass('fadeOut').addClass('fadeIn');
                        }
                        if (scrollBottom < (finalistFraction * 4)) {
                            $('.finalist-bg-container__card--four').removeClass('fadeOut').addClass('fadeIn');
                        }
                    }
                    lastScrollTop = scrollTop;


                }, this))));
            }, this));
        }
    };
    $(document).ready(methods.onDocumentReady);
    return methods;
});

