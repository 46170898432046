define('modules/process/slidecard/js/base.js',["app/module","app/config","jquery","app/util","templates","swiper.jquery.min","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({
        name: 'process.slidecard',
        selectors: '.process-slidecard-wrap',
        remoteData: [(config.urls.process)],
        
        config: {
            
        },

        routes: [{
            route: 'home',
            extra: true,
            delay: 2000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        }],

        initialize: function (data) {
            this.data = data;
            this.renderTemplates(this.data);
        },

        onRender: function() {
            util.setVisibleInterval($(this.getSelectors()),$.proxy(this.initSwiper,this),1000);  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
            util.setVisibleInterval($(this.getSelectors()),$.proxy(this.attachProcessInkResizeEvent,this),1000);
        },

        methods: {

            renderTemplates: function (data) {
                $(this.getSelectors()).append(templates.processslidecard({
                    steps: data.sort(function(a, b) {
                        return a.id - b.id;
                    })
                }));
            },

            initSwiper: function() {

                if(this.swiperInstance) {
                    return;
                }

                this.swiperInstance = new Swiper('.swiper-container-process', {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 60,
                    loop: false,
                    //next lines remove the touch functionality and the mousedrag (simulating touch)
                    simulatesTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: true,
                    prevButton: ".process-swiper-navigate-prev",
                    nextButton: ".process-swiper-navigate-next",
                    preventClicks: false,
                    preventClicksPropagation: false,
                    breakpoints: {
                        768: {
                            pagination: '.process-swiper-pagination',
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true,
                            onlyExternal: false,
                        }
                    }
                });

                //process animations demo for lahc2017 project
                var processSwiper = $('.swiper-container-process')[0].swiper;

                var processSlidecardWrap = $('.section.home-process'),
                    activeSlide;

                processSlidecardWrap.find('.ink-slide:first-child').addClass('process-ink-animated');

                processSwiper.on('slideNextStart', function() {
                    activeSlide = processSwiper.activeIndex;
                    processSlidecardWrap.find('.process-ink-animated').removeClass('process-ink-animated process-ink-animated-prev process-ink-animated-next');
                    processSlidecardWrap.find('.ink-slide-' + activeSlide).addClass('process-ink-animated process-ink-animated-next');
                });

                processSwiper.on('slidePrevStart', function() {
                    activeSlide = processSwiper.activeIndex;
                    activeSlide = parseInt(activeSlide);
                    activeSlide = activeSlide + 1;
                    processSlidecardWrap.find('.process-ink-animated').removeClass('process-ink-animated process-ink-animated-prev process-ink-animated-next');
                    processSlidecardWrap.find('.ink-slide-' + activeSlide).addClass('process-ink-animated process-ink-animated-prev');
                });
            },

            attachProcessInkResizeEvent: function() {

                //keeping proper dimensions for the .page-ink-layer on windows resize - landscape or portrait
                var inkElement = $('.resizable-ink-element-to-parent'),
                    frameProportion = inkElement.attr('data-ink-framerate'), // this is the proportion of a single frame from the png - width/height
                    frameProportion = parseFloat(frameProportion),
                    frames = inkElement.attr('data-ink-frames'), // number of frames
                    frames = parseInt(frames),
                    inkElementParent = inkElement.closest('.resizable-ink-element-parent'),
                    parentWidth,
                    parentHeight,
                    inkLayerWidth,
                    inkLayerHeight;

                function updateProcessInkSize() {

                    parentWidth = inkElementParent.width();
                    parentHeight = inkElementParent.height();

                    if( parentWidth/parentHeight > frameProportion ) {
                        inkLayerWidth = parentWidth+17;
                        inkLayerHeight = inkLayerWidth/frameProportion;
                    } else {
                        inkLayerHeight = parentHeight;
                        inkLayerWidth = inkLayerHeight*frameProportion;
                    };

                    inkElement.css({
                        'width': inkLayerWidth*frames+'px',
                        'height': inkLayerHeight+'px',
                    });
                };

                updateProcessInkSize();

                $(window).on('resize', updateProcessInkSize);
            }
        }
    });
});

