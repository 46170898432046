define('modules/navigation/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "app/redirect", "wow"], function (module, config, $, util, redirect) {
    return new module({
        name: 'navigationbasic',
        selectors: false,
        remoteData: [],
        extendable: true,

        lastRoute: null, // Null defaults to home

        routes: [{
            route: '.*',
            fn: function (hash) {
                return this.navigate(hash);
            },
            priority: 10
        }, {
            route: '.*',
            fn: 'trackRoutes',
            extra: true
        }, {
            route: '.*',
            fn: 'routeReInit',
            extra: true
        }, {
            route: 'top',
            fn: function () {
                this.navigate('home');
                util.scrollBodyToTop(500, true);
            },
            priority: 5
        }],

        config: {
            animationType: false,
            smoothNavigate: false,
            loadingScreen:true,
            wowJs:false
        },

        initialize: function (data) {
            this.$container = $(this.getSelectors());

            if (this.animationType) {
                this.initAnimation();
            }
        },

        onRender: function () {

        },

        methods: {
            initAnimation: $.noop, // TODO - Use our animations

            navigate: function (hash) {
                //>>excludeStart("production",pragmas.production);
                console.debug('Basic navigation module trigger: ', hash);
                //>>excludeEnd("production");
                hash = this.setDefaultRoute(hash);
                if (hash) {
                    var pages = this.getNewPage(hash);
                    if (pages) {
                        this.switchPages(pages);
                    } else {
                        return false;
                    }
                }
            },

            setDefaultRoute: function (hash) {
                // Set the default starting route
                if ((!hash && this.lastRoute === null) || (hash.length < 1)) {
                    return 'home';
                } else if (this.lastRoute == hash) {
                    return false;
                }
                return hash;
            },

            getNewPage: function (hash) {
                // Get our pages
                var currentPage = $('.pageContainer[data-route="' + this.lastRoute + '"]'),
                    newPage = $('.pageContainer[data-route="' + hash.split('/')[0] + '"], .contentRedirect[data-route="' + hash.split('/')[0] + '"], *[data-route-section][data-route="' + hash.split('/')[0] + '"]');
                if (!newPage || newPage.length === 0 || $(currentPage).is($(newPage))) {
                    return false;
                } else {
                    if ($(newPage).attr('data-route-section')) {
                        var sectionPage = $('.pageContainer[data-route="' + $(newPage).attr('data-route-section') + '"]');
                        if (sectionPage.length > 0) {
                            return [currentPage, sectionPage, newPage];
                        } else {
                            return false;
                        }
                    } else {
                        return [currentPage, newPage];
                    }
                }
            },

            switchPages: function (pages) {
                var currentPage = pages[0],
                    newPage = pages[1].first(),
                    relayPage = pages[2];
                if (currentPage && $(currentPage).length > 0 && $(currentPage).attr('data-route-trackscroll') != undefined) {
                    $(currentPage).attr('data-route-trackscroll', util.getScrollParent($(currentPage)).scrollTop());
                }
                if (newPage.attr('data-redirect-target')) {
                    if ($(newPage.attr('data-redirect-target')).parents('#mainMenu').length > 0) { //&& !$(newPage).parents('#mainMenu').hasClass('open')) {
                        $('body').trigger('updatedashboard', [true, newPage.clone(true, true).removeClass('hidden')]);
                        $('#centerMenuContent').addClass('sidebarPage');
                    } else {
                        redirect.target(newPage, $(newPage.attr('data-redirect-target')));
                    }
                } else {
                    $('.pageContainer').removeClass('active').hide();
                    $('.pageContainer .section').removeClass('active');
                    $(newPage).addClass('active').show().css('opacity', 1);
                    if (relayPage && $(relayPage).length > 0) {
                        $(relayPage).get(0).scrollIntoView();
                    } else {
                        if (typeof $(newPage).attr('data-route-trackscroll') != 'undefined') {
                            util.getScrollParent($(newPage)).scrollTop($(newPage).attr('data-route-trackscroll'));
                        } else {
                            if (this.smoothNavigate) {
                                util.scrollBodyToTop();
                            }
                            else {
                                util.scrollBodyToTop(0);
                            }
                        }
                    }
                }
            },

            trackRoutes: function (hash) {
                this.lastRoute = hash;
            },

            routeReInit: function (hash) {
                // anything that needs reinit on route update
                var wow = new WOW({mobile: true, live: true});

                if ($.inArray(hash, ['register', 'login', 'forgot', 'reset']) >= 0) {
                    // don't want to reinit on these routes
                }
                else {
                    if (this.getConfig().loadingScreen) {
                        if (this.getConfig().wowJs) {
                            if ($('.custom-loader-element').hasClass('load-finished')) {
                                wow.init();
                            }
                            else {
                                // do nothing still loading
                            }
                        }
                        else {
                            // have loading no wow do nothing
                        }
                    }
                    else {
                        console.log('no loading screen')
                        // no loading using wow js
                        if (this.getConfig().wowJs) {
                            // reinit init wow on route update
                            $('.loading-to-animate').addClass('fire-animation');
                            setTimeout(function () {
                                //$('.navbarWrap .wow').removeClass('wow');
                                $('.fetch_home_top_card p').each(function (index) {
                                    var $this = $(this);
                                    setTimeout(function () {
                                        $this.addClass('show-p')
                                    }, 100 * index);
                                });
                                wow.init();
                            }, 2500);
                        }
                        else {
                            // no loading no wow do nothing
                            // we would have any wow or animation classes going on anyway
                        }
                    }

                }
            }
        }
    });
});

