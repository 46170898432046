define('modules/dashboardmenu/js/base.js',["app/module","app/config","app/user","jquery","app/util","templates","app/forms","jquery.cookie","bootstrap/popover"], function(module,config,user,$,util,templates,forms) {
    return new module({
        name: 'dashboardmenu',
        selectors: '.mainMenuWrap',
        remoteData: [],

        config: {
            // TODO - Set up the proper or combined modal mode
            // TODO - Set up the data-attr apply config method
            mode: 'sidebaronly', // Two modes - full or sidebaronly
            pushPageContent: 'left', // Opening the menu pushes the page content to the left/right, defaults to left
            hoverMenuEnabled: false, // TODO - This is not implemented in any logic below
            formLocation: null,
            hideMenuOnForms: false,
            updateMenuText: true,
            delayOutTransition: false,
            openRoutes: ['register','login','forgot','reset'] // Default for forms
        },

        routes: [{
            route: '.*',
            fn: function(hash){
                if(!this.getConfig().formLocation) {
                    this.updateToggleMenu(false);
                } else if(this.getConfig().openRoutes.indexOf(hash.split('/')[0]) == -1) {
                    if(this.appLoaded) {
                        this.updateToggleMenu(false);
                    } else {
                        this.appLoaded = true;
                    }
                }
            },
            extra: true,
            delay: 150
        },{
            route: 'menu',
            fn: 'routeDashboardLinks',
            extra: true
        },{
            route: 'register',
            fn: 'routeRegister',
            silent: true,
            delay: 200
        },{
            route: 'login',
            fn: 'routeLogin',
            silent: true,
            delay: 200
        },{
            route: 'forgot',
            fn: 'routeForgot',
            silent: true,
            delay: 200
        },{
            route: 'reset',
            fn: 'routeReset',
            silent: true,
            delay: 200
        }],

        initialize: function (data) {

            // Render the template
            $(this.getSelectors()).html(templates.dashboardmenu({
                config: config // TODO - Filter this down to selected keys
            }));

            // Check initial config options
            if(this.getConfig().mode == 'sidebaronly') {
                $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').hide(); // Initial state, we reset via CSS display inherit
            }
            if(this.getConfig().hideMenuOnForms) {
                $(this.getConfig().formLocation).hide();
            }

            var mainMenu = $('#mainMenu');

            // Only applies if the mainMenu is present
            if(mainMenu.length > 0) {

                if(user.checkRole('loggedin')) {
                    if(!this.getConfig().hideMenuOnForms) {
                        this.updateToggleMenu(templates.dashboardlinks());
                        $('#actionMenu').toggleClass('dashboardLinksShown',true);
                    }
                    // If the user is logged in, update the days left to submit and logout link
                    $('#mainNavbar a[href*="login"]').text('Logout').attr('href','/logout.aspx');
                    if(config.rampitSubTimeLeft >= 0) {
                        $('#mainNavbar a[href*="register"]').html('<span class="dayLeft"></span> Days to Submit').attr('href','#');
                    }
                } else if(user.checkRole('registered')) {
                    if(!this.getConfig().hideMenuOnForms) {
                        this.updateToggleMenu(templates.login({config: config}));
                        $('#actionMenu').toggleClass('loginShown',true);
                    }
                } else {
                    if(!this.getConfig().hideMenuOnForms) {
                        this.updateToggleMenu(templates.register(config.regConfig));
                        $('#actionMenu').toggleClass('registerShown',true);
                    }
                }

                // Detect and handle close button
                $('.dashboardToggle, #mainMenu .closeWrap').on('click',$.proxy(function(e){
                    e.preventDefault();
                    this.updateToggleMenu(!$('#mainMenu').is(':visible'),false);
                    return false;
                },this));

                // Add body listener for menu status control
                $('body').on('updatedashboard',$.proxy(function(e,a,b,c){
                    this.updateToggleMenu(a,b,c);
                },this));

            }

            // Navbar flyout
            $('.partnerLink, .partnerFlyout .closeBtn').click(function(e){
                e.preventDefault();
                $('.partnerFlyout').toggleClass('masked');
                setTimeout(function(){
                    $('.partnerFlyout').toggleClass('open');
                    $('.introPage').toggleClass('bumpLeft');
                },50);
            });

            $('.flyoutTrigger, .rightFlyout .closeBtn, .leftFlyout .closeBtn').click(function(e){
                e.preventDefault();
                var flyoutEl = $(this).attr('data-flyout') || $(this).parents('.rightFlyout, .leftFlyout');
                $(flyoutEl).toggleClass('masked');
                setTimeout(function(){
                    $(flyoutEl).toggleClass('open');
                    $('.introPage').toggleClass('bumpRight');
                },50);
            });

            // Add tooltip dropdown support about button
            /*var popoverHtml = $('<ul class="aboutPopoverList"><li><a href="about.html">About</a></li><li><a href="#">About Two</a></li></ul>');
             $('.aboutHoverBtn').hover(function(){
             $(this).focus();
             }).popover({
             content: popoverHtml,
             html: true,
             placement: 'bottom',
             container: 'nav',
             trigger: 'focus',
             delay: {
             show: 0,
             hide: 250
             }
             });*/
        },

        methods: {

            updateToggleMenu: function (open, content, location) {

                // Check if we are just passing content, in which case do it silently
                if (typeof open == 'string' || typeof open == 'object') {
                    content = open;
                    open = null;
                }

                // Fire a global app event for open/close status
                if (open) {
                    $('body').trigger('mainnavopen');
                } else {
                    $('body').trigger('mainnavclose');
                }
                
                if ($('body').hasClass('delayOutTransition')) {
                	// handling for class if needed
                }
                else {
					if(this.getConfig().hideMenuOnForms && this.getConfig().formLocation) {
						$(this.getConfig().formLocation).hide();
						$('#sideMenu').show();
					}
                }


                if ($('#mainMenu').length > 0) {

                    // Reset visible elements
                    $('#mainMenu .actionContent').show(); //, #mainMenu .daysPromo').show();
                    $('#actionMenu').removeClass('hasMessage largeSuccess');

                    // Set the template content
                    if (content) {
                        var location = this.getConfig()['formLocation'];
                        if(location) {
                            $(location).html(content);
                            $(location).show();
                        } else {
                            $('#mainMenu .actionContent').html(content);
                        }
                    }

                    // Trigger the opening animation
                    if (open !== null) {
                        if (open == false) {

                            // finalist fix
                            if (!$('.event-rsvp').hasClass('shrink')) {
                                $('.event-rsvp').removeClass('overlay-open');
                            }

                            $('.navbarWrap').removeClass('move');
                            $('.navbar .navbar-header').removeClass('hideNav');
                            $('#mainMenu').addClass('closing');
                            $('#mainMenu').removeClass('open');
                            //$('.navbarWrap').addClass('slideUpOut');
                            $('.daysToRegister').removeClass('mobileNavVisible');
                            $('.hamburger-to-close').removeClass('changeclose');
                            $('.dashboardToggle').removeClass('open');
                            // $('.sidebar-item').removeClass('sidebar-item-selected');
                            $('.dashboard-footer-menu').fadeOut();
                            $('.singlePageWrap').removeClass('pushContentLeft pushContentRight');
                            $('#centerMenuContent').removeClass('sidebarPage');
                            $('body').removeClass('locked');
                            if(this.getConfig().updateMenuText) {
                                $('.dashboard-menu-text').text('Menu');
                            }
                            if(!$('html').hasClass('touch')) {
                                util.lockScrolling(false, '#sideMenu .menuWrap');
                            }
                            setTimeout($.proxy(function () {
                                $('#mainMenu').hide();
                                $('#mainMenu').removeClass('closing');
                                if (this.getConfig().formLocation) {
                                    if(this.getConfig().openRoutes.indexOf(window.location.hash.substr(1).split('/')[0]) > -1) {
                                        window.location.hash = '';
                                    }
                                }
                                if(this.getConfig().mode == 'sidebaronly') {
                                    $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').hide();
                                }
                            }, this),1000);
                        }
                        else {

                            if($('#mainMenu').hasClass('closing')) {
                                return;
                            }

                            // finalist fix
                            if (!$('.event-rsvp').hasClass('shrink')) {
                                $('.event-rsvp').addClass('overlay-open');
                            }

                            $('#mainMenu').show();
                            $('.dashboard-footer-menu').fadeIn();
                            $('body').addClass('locked');
                            $('.dashboardToggle').addClass('open');
                            $('.daysToRegister').addClass('mobileNavVisible');
                            $('.hamburger-to-close').addClass('changeclose');
                            $('.navbarWrap').addClass('move');
                            $('.navbar .navbar-header').addClass('hideNav');
                            if(this.getConfig().updateMenuText) {
                                $('.dashboard-menu-text').text('close');
                            }
                            if(!$('html').hasClass('touch')) {
                                util.lockScrolling(true, '#sideMenu .menuWrap');
                            }
                            setTimeout($.proxy(function () {
                                $('#mainMenu').addClass('open');
                                //$('.navbarWrap').addClass('slideDownIn');
                                if (this.getConfig().pushPageContent && this.getConfig().pushPageContent == 'left') {
                                    $('.singlePageWrap').addClass('pushContentLeft');
                                } else if (this.getConfig().pushPageContent && this.getConfig().pushContent == 'right') {
                                    $('.singlePageWrap').addClass('pushContentRight');
                                }
                            }, this), 30);
                        }
                    }

                } else {
                    // TODO - Refactor this into the modal methods
                    $('body').append($(templates.actionmodal({
                        content: content
                    })).modal());
                }

                // Pause any videos that are currently playing
                $('video').each(function (key, videoEl) {
                    $(videoEl).get(0).pause();
                });

                forms.refreshFormHandlers();

            },

            initMenuHover: function (data) {
                // Add tooltip dropdown support to the menu
                var topMenuHtml = $('<ul></ul>');
                // Add the top title that opens the mainmenu
                topMenuHtml.append($('<h5>Menu Dashboard</h5>').click($.proxy(function (e) {
                    this.updateToggleMenu(true, false);
                }, this)));
                $.each(data, function (index, topLink) {
                    if (topLink.hovermenu) {
                        topMenuHtml.append($('<li><a href="' + topLink.link + '">' + topLink.text + '</a></li>'));
                    }
                });
                $('.dashboardToggle > a').hover(function () {
                    $(this).focus();
                    $(this).mouseleave(function (e) {
                        var target = e.toElement || e.relatedTarget;
                        if (!$(target).hasClass('popover') && $(target).parents('.popover').length == 0
                            && !$(target).hasClass('navbar')) {
                            $('#mainNavbar .popover').fadeOut();
                        }
                    });
                    $('#mainNavbar').find('.popover').mouseleave(function () {
                        $('#mainNavbar .popover').fadeOut();
                    });
                }).popover({
                    content: topMenuHtml[0],
                    html: true,
                    placement: 'bottom',
                    container: 'nav',
                    trigger: 'focus',
                    delay: {
                        show: 0,
                        hide: 500
                    }
                });

            },

            routeDashboardLinks: function () {

                if (!this.getConfig().formLocation) {
                    return;
                } else if(this.getConfig().mode == 'sidebaronly') {
                    $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').css('display','inherit');
                }

                this.updateToggleMenu(true, templates.dashboardlinks());
                $('#actionMenu').toggleClass('dashboardLinksShown', true);
                // If the user is logged in, update the days left to submit and logout link
                $('#mainNavbar a[href*="login"]').text('Logout').attr('href', '/logout.aspx');
                if (config.rampitSubTimeLeft >= 0) {
                    $('#mainNavbar a[href*="register"]').html('<span class="dayLeft"></span> Days to Submit').attr('href', '#');
                }
            },

            routeRegister: function () {

                if (this.getConfig().delayOutTransition) {
                    $('#sideMenu').addClass('form-open');
                    $('.side-menu-ink-layer-wrapper').addClass('form-open');
                }

                if (!this.getConfig().formLocation) {
                    return;
                } else if(this.getConfig().mode == 'sidebaronly') {
                    $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').css('display','inherit');
                }

                if (user.checkRole('loggedin')) {
                    this.updateToggleMenu(true);
                    return;
                }

                $('#actionMenu').toggleClass('loginShown hasMessage largeSuccess', false);
                $('#sideMenu').addClass('mobAway');

                if (config.rampitRegTimeLeft < 1) {
                    this.updateToggleMenu(true, templates.validationmessage({
                        message: (config.regConfig ? (config.regConfig.closeMessage || '') : ''),
                        messageTitle: (config.regConfig ? (config.regConfig.closeTitle || '') : ''),
                    }));
                    $('#actionMenu').toggleClass('regClosed', true);
                    $('#centerMenuContent').prepend('<i class="material-icons register-login-forms-close">close</i>');
                } else {
                    this.updateToggleMenu(true, templates.register(config.regConfig));
                    $('#actionMenu').toggleClass('registerShown', true);
                }
                this.setFormComponent();
                this.handleCloseLoginRegister();
                
            },

            routeLogin: function () {

                if (this.getConfig().delayOutTransition) {
                    $('#sideMenu').addClass('form-open');
                    $('.side-menu-ink-layer-wrapper').addClass('form-open');
                }

                if (!this.getConfig().formLocation) {
                    return;
                } else if(this.getConfig().mode == 'sidebaronly') {
                    $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').css('display','inherit');
                }

                if (user.checkRole('loggedin')) {
                    this.updateToggleMenu(true);
                    return;
                }

                $('#actionMenu').toggleClass('registerShown hasMessage largeSuccess', false);
                $('#actionMenu').toggleClass('loginShown', true);
                $('#sideMenu').addClass('mobAway');

                if (config.regConfig.type == 'expired') {
                    this.updateToggleMenu(true, templates.validationmessage({
                        message: 'Login Expired'
                    }));
                } else if (util.getParameterByName('token').length) {
                    this.updateToggleMenu(true, templates.resetpassword());
                } else {
                    this.updateToggleMenu(true, templates.login({config: config}));
                }
                this.setFormComponent();
                this.handleCloseLoginRegister();

            },


            routeForgot: function () {

                if (this.getConfig().delayOutTransition) {
                    $('#sideMenu').addClass('form-open');
                    $('.side-menu-ink-layer-wrapper').addClass('form-open');
                }

                if (!this.getConfig().formLocation) {
                    return;
                } else if(this.getConfig().mode == 'sidebaronly') {
                    $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').css('display','inherit');
                }

                if (user.checkRole('loggedin')) {
                    this.updateToggleMenu(true);
                    return;
                }

                $('#sideMenu').addClass('mobAway');
                this.updateToggleMenu(true, templates.forgotpassword());
                this.setFormComponent();
                this.handleCloseLoginRegister();
            },

            routeReset: function () {

                if (this.getConfig().delayOutTransition) {
                    $('#sideMenu').addClass('form-open');
                    $('.side-menu-ink-layer-wrapper').addClass('form-open');
                }

                if (!this.getConfig().formLocation) {
                    return;
                } else if(this.getConfig().mode == 'sidebaronly') {
                    $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').css('display','inherit');
                }

                $('#sideMenu').addClass('mobAway');
                this.updateToggleMenu(true, templates.resetpassword());
                this.setFormComponent();
                this.handleCloseLoginRegister();
            },

            setFormComponent: function() {
                if(this.getConfig().hideMenuOnForms) {
                    $('#sideMenu').hide();
                    $('#centerMenuContent').css('width','auto').addClass('center-menu-open');
                }
            },

            handleCloseLoginRegister: function() {
            	$('#mainMenu .register-login-forms-close').on('click',$.proxy(function(e){
                	
                	if(!$('.navMapWrap').hasClass('closed')) {
                		$('.mapClose').trigger('click');
                	}
                	if(this.getConfig().mode == 'sidebaronly') {
                        $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').hide();
                    }
                    if (this.getConfig().delayOutTransition) {
                    	$('body').addClass('delayOutTransition');
                        setTimeout($.proxy(function () {
                            $('#sideMenu').removeClass('form-open');
                            $('.side-menu-ink-layer-wrapper').removeClass('form-open');
                            $('#centerMenuContent').removeClass('center-menu-open');
                            $('body').removeClass('delayOutTransition');
                        }, this),1200);
                    }
                    else {
                        $('#centerMenuContent').removeClass('center-menu-open');
                    }
                
                	$('#mainMenu .closeWrap').trigger('click');
                    $('.zoom-el.transition-out').removeClass('transition-out'); // TODO - This is specific to scenebg module, should be refactored out of this module
                },this));
            }
        }
    });
});
