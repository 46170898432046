// require all needed dependencies here
define('modules/finalists/basic/js/base.js',["app/config","jquery","app/module","app/util","templates"], function(config,$,module,util,templates) {
    return new module({
        name: 'finalists',
        selectors: '.finalistsWrapper',
        remoteData: [config.urls.finalists],

        // every matching route will be called on every change in the browser's url
        // routes: [{
        //     route: 'news/(.*)',
        //     fn: function(hash) {
        //         this.showNews();
        //         this.openNewsPost(hash.split('/')[1]);
        //     }
        // },{
        //     route: 'news',
        //     fn: 'showNews'
        // }],

        // external config options. Can be overriden in the project's manifest
        // please ensure every option in use has a default value here and optionally a short description what it does
        // config: {
        //     externalOption: 42
        // },

        // once the remote data is fetched this method will be called
        initialize: function (data) {
            // the selector will be always present in the DOM, please use the cached version whenever possible
            this.$container = $(this.getSelectors());
            this.data = data;
        
            this.renderTemplates(data);
            this.attachEventHandlers();
            this.setInitialState();
        },

        // once the executon of initialize has ended this method will be called
        onRender: function(){

        },

        methods: {
            renderTemplates: function(data){
                // modify the data if needed and prepare a view model forrendering the view
                var viewModel = {
                    finalistItems: data
                };

                // render the template and append it to the container
                // most of the time we are refering to a Handlebars template existing in the module itself
                // the template can be overriden if a file with the same name exists in the project specific folder
                this.$container.append(templates.finalists(viewModel));
            },

            attachEventHandlers: function(data){
                this.$container.on('click', $.proxy(function(event){
                    // using jQuery's proxy function we will have access to the module using "this" keyword
                }, this));
            },

            setInitialState: function(data){

            }
        }

    });
});
