define('modules/judges/sidebyside/js/base.js',["app/config", "app/util", "jquery", "templates", "skrollr", "waypoints", "jquery.mousewheel.min", "jquery.jscrollpane.min"], function (config, util, $, templates, skrollr) {
    return {
        name: 'judges',
        selectors: '.judgePageWrap, .judgeSection',
        remoteData: [config.urls.judges],
        routes: [{
            route: 'judges/(.*)',
            fn: function (hash) {
                $('#judges .judgebox[data-judge="' + hash.split('/')[1] + '"]').trigger('click');
            },
            extra: true,
            delay: 2500
        }, {
            route: 'judges',
            fn: 'showLeftSide',
            extra: true,
            delay: 500
        }],

        leftWaypoints: [],

        config: {
            rightPanelLayout: true,
            showTypeBar: false,
            useFallbackMethods: false
        },


        initialize: function (data) {

            // Figure out the grouping for judges and render the page template
            this.renderTemplates(data);

            // top buttons scroll to section
            $('.introBtnContainer .btn').click(function (e) {
                e.preventDefault();
                var el = $(this),
                    group = $(el).attr('data-judgegroup');
                // TODO - Convert this to use util methods and detect scroll parent
                $('html,body').animate({
                    scrollTop: $('.judgeGroup[data-judgegroup="' + group + '"]').offset().top
                }, 500);
                $('body').trigger('navbartoggle', false);
                Waypoint.refreshAll();
            });

            // TODO Set this up as config option with selector
            // cloning this container because we need it in the intro section
            //var btnContainerClone = $('.introBtnContainer').clone(true);
            //$('.introBtnContainer').remove();
            //$('.judgesPageContainer .btn-clone-container').html(btnContainerClone);

            // Add the click handlers to judgeboxes
            $(this.selectors).find('.judgebox').on('click', $.proxy(this.onJudgeBoxClick, this));

            // Init Skrollr
            var lastScrollTop = 0;
            // Bottom toggle bar navigation, this mostly chains to other methods
            $('.judgeTypeBar span, .typeIcon').click($.proxy(function (e) {
                e.preventDefault();

                var el = $(e.delegateTarget),
                    group = $(el).attr('data-judgegroup');

                $($('.judgeGroup[data-judgegroup="' + group + '"]').scrollParent()).animate({
                    scrollTop: $('.judgeGroup[data-judgegroup="' + group + '"]').offset().top
                }, 500);
                Waypoint.refreshAll();

                /*//var st = $('.judgeGroup[data-judgegroup="'+group+'"]').scrollTop();
                 var st = $('.judgeGroup[data-judgegroup="'+group+'"]').offset().top;
                 //var lastScrollTop = 0;
                 if($(el).hasClass('backToggle')) {
                 this.showLeftSide(group);
                 } else if($('#judgesDetail:visible').length > 0) {
                 this.showRightSide(group);
                 } else {

                 // We are still on the left side and scrolling within
                 if (st > lastScrollTop){
                 // if we're scrolling down less top padding
                 util.scrollElToTop($('.judgeGroup[data-judgegroup="'+group+'"]'),-40);
                 } else {
                 // if we're scrolling up more top padding
                 util.scrollElToTop($('.judgeGroup[data-judgegroup="'+group+'"]'),-220);
                 }
                 lastScrollTop = st;

                 //util.scrollElToTop($('.judgeGroup[data-judgegroup="'+group+'"]'),-40);
                 Waypoint.refreshAll();
                 }*/
            }, this));

            // Waypoint for toggling the active tab for judges group
            $('.judgeGroup').each($.proxy(function (index, el) {
                var waypoint = new Waypoint({
                    offset: 200,
                    element: $(el),
                    handler: $.proxy(function () {
                        $('.judgeTypeBar span').toggleClass('enabled', false);
                        $('.judgeTypeBar span[data-judgegroup="' + $(el).attr('data-judgegroup') + '"]').toggleClass('enabled', true);
                    }, this)
                });
                this.leftWaypoints.push(waypoint);
                // Secondary waypoint with 1px offset, due to scrollTo handling in the up direction with an exact offset (see https://github.com/imakewebthings/waypoints/issues/346#issuecomment-65501126)
                // TODO - P2 Make a Waypoint adapter with this in it and other common use cases
                var waypoint = new Waypoint({
                    offset: 199,
                    element: $(el),
                    handler: $.proxy(function (direction) {
                        if (direction == 'up') {
                            $('.judgeTypeBar span').toggleClass('enabled', false);
                            $('.judgeTypeBar span[data-judgegroup="' + $(el).attr('data-judgegroup') + '"]').toggleClass('enabled', true);
                        }
                    }, this)
                });
                this.leftWaypoints.push(waypoint);
            }, this));


            // Apply hashchange for direct judge routing
            /* $(window).hashchange($.proxy(function(){
             if(location.hash.split('/')[0].substr(1) == 'judges') {
             skrollr.get().refresh();
             if(location.hash.split('/')[1]) {
             // Get existing hash and use it to trigger the click
             $('#judges .judgebox[data-judge="'+location.hash.split('/')[1]+'"]').trigger('click');
             } else {
             this.showLeftSide();
             }
             }
             },this));*/
            this.mobileToggleBar();
            this.refreshAnim();

            // TODO - We need this to handle a resize event on the height, however it has the potential to cause additional issues/regression
            // If necessary, disable the below line and just leave it without height resizer
            // TODO the debounce is a little finicky, moving to a clear timeout
            //$(window).smartresize($.proxy(this.alignRightScroll,this));

            var resizeTimer;
            var module = this;

            $(window).on('resize', function (e) {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout($.proxy(function () {
                    module.alignRightScroll();
                }, this), 250);
            });
        },

        // Groups and renders the template data
        renderTemplates: function (data) {
            var judgeGroups = [],
                templateData = {
                    judgeGroups: []
                };
            $.each(data, function (index, record) {
                if (!judgeGroups[record.type]) {
                    judgeGroups[record.type] = {
                        name: record.type,
                        title: record.shortDescription,
                        cms: record.cms,
                        desc: record.desc,
                        descTitle: record.descTitle,
                        records: []
                    };
                }
                judgeGroups[record.type].records.push(record);
            });
            for (var key in judgeGroups) {
                templateData.judgeGroups.push(judgeGroups[key]);
            }
            this.templateData = templateData;
            $('.judgePageWrap').append(templates.judgespage({
                data: templateData,
                config: this.config
            }));
        },

        // Handle for when a judge is clicked on, this can also be manually triggered
        onJudgeBoxClick: function (e) {

            e.preventDefault();
            $.each(this.leftWaypoints, function () {
                this.disable();
            });

            var jGroup = $(e.delegateTarget).attr('data-judgegroup'),
                jId = $(e.delegateTarget).attr('data-judge');

            if (this.config.rightPanelLayout) {
                this.showRightSide(jGroup);
                $('#judgesDetail').find('.tplWrapper').css('overflow', 'scroll');
                $('#judgesDetail').find('.tplWrapper').scrollTop($('#judgesDetail').find('*[data-pagenav][data-id="' + jId + '"]').position().top + 1);
                $('#judgesDetail').find('.activeJudgeDetail').removeClass('activeJudgeDetail');
                $('#judgesDetail').find('*[data-pagenav][data-id="' + jId + '"]').addClass('activeJudgeDetail');
                setTimeout($.proxy(function () {
                    $('body').trigger('pagenavupdate');
                    $('#judgesDetail').find('.tplWrapper').css('overflow', 'hidden');
                }, this), 5);

            } else {
                $('#judgesDetail').show();
            }

            setTimeout($.proxy(function () {
                $('body').addClass('judgeDetailOpen');
                if (skrollr.get()) {
                    skrollr.get().refresh(); // Refresh skrollr for new height layout changes
                }
                //setTimeout(function(){
                //    $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut'); 
                //},60); // TODO - We need to address this in a better way to handle the temporary flash, attempt to reduce timeout
            }, this), 50);

            // TODO possibly make this a permanent solution

            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');

            setTimeout(function () {
                $('.navbarWrap').addClass('hidden');
                // finalist fix
                if (!$('.event-rsvp').hasClass('shrink')) {
                    $('.event-rsvp').addClass('overlay-open');
                }
            }, 60);

            // Update the text on the judges bar
            this.resetToggleBar(jGroup, true);
            this.mobileToggleBar();

            $('#judgesDetail .judgeInfoGroup').toggle(false);
            $('#judgesDetail .judgeInfoGroup[data-judgegroup="' + jGroup + '"]').toggle(true);

            if (!this.config.rightPanelLayout) {
                if ($(window).width() >= 768) {
                    if ($('.judgeinfoWrap[data-judge="' + jId + '"]').offset().top < 200) {
                        util.scrollElToTop($('.judgeinfoWrap[data-judge="' + jId + '"]'), -220);
                    } else {
                        util.scrollElToTop($('.judgeinfoWrap[data-judge="' + jId + '"]'), -40);
                    }
                } else {
                    util.scrollElToTop($('.judgeinfoWrap[data-judge="' + jId + '"]'), -20);
                }
                $('body').trigger('pagenavupdate');
            }

            //this is for scrollbar in lahc2017 project
            /*$('#judgesDetail').find('.scroll-pane').jScrollPane(
                {
                    showArrows: true,
                    verticalGutter: 0,
                    verticalDragMinHeight: 40,
                    verticalDragMaxHeight: 40,
                    mouseWheelSpeed: 10,
                    verticalArrowPositions: 'split',
                    horizontalArrowPositions: 'split',
                    animateScroll: false
                }
            );*/


            this.initJSP();

            return false;
        },

        initJSP: function () {
            // var jspApi = $(this.getSelectors()).find('.scroll-pane').data('jsp');
            var jspScrollSpeed;
			
			if ($('html').hasClass('mac')) {
				jspScrollSpeed = 2;
			}
			else {
				// fallback/default
				jspScrollSpeed = 40;
			}

            if ($(window).width() < 768) {
                //mobile stuff
                if (this.jspApi) {
                    this.jspApi.destroy();
                    this.jspApi = null;
                }
            }
            else {

                //desktop stuff
                this.jspApi = $('#judgesDetail').find('.scroll-pane').jScrollPane(
                    {
                        showArrows: true,
                        verticalGutter: 0,
                        verticalDragMinHeight: 40,
                        verticalDragMaxHeight: 40,
                        mouseWheelSpeed: jspScrollSpeed,
                        verticalArrowPositions: 'split',
                        horizontalArrowPositions: 'split',
                        animateScroll: false
                    }
                ).data('jsp');
            };
        },

        resetToggleBar: function (active, rightside) {

            if (rightside && this.config.rightPanelLayout) {
                $('.judgeTypeBar').hide();
            } else {
                $('.judgeTypeBar').show();
            }

            $('.nav .typeIcon').each(function () {
                $(this).text($(this).attr('data-judgegroup'));
            });

            $('.judgeTypeBar span').removeClass('backToggle enabled view');
            $('.judgeTypeBar span').each(function () {
                $(this).text($(this).attr('data-judgegroup'));
                if (active && active == $(this).attr('data-judgegroup') && !rightside) {
                    $(this).addClass('enabled');
                }
                if (active && active == $(this).attr('data-judgegroup') && rightside) {
                    $(this).addClass('backToggle');
                } else if (rightside) {
                    $(this).addClass('view');
                }
            });
        },


        // Moving back to the left side
        showLeftSide: function (judgegroup) {


            // Reset elements
            $('body').removeClass('judgeDetailOpen locked');

            // without changing the css to get rid of the borderbox from judgegroup
            // on judge detail view
            $('#judges .judgeGroup').css('left', '0px'); // TODO - Move to CSS


            this.resetToggleBar(judgegroup);
            this.mobileToggleBar();

            $.each(this.leftWaypoints, function () {
                this.enable();
            });

            var scrollBackToEl = $('.judgeGroup[data-judgegroup="' + (judgegroup || '') + '"]');
            setTimeout($.proxy(function () {
                if (this.config.rightPanelLayout) {
                    this.refreshAnim();
                } else {
                    $('#judgesDetail').hide();
                    this.refreshAnim();
                    if ($(window).width() >= 768) {
                        util.scrollElToTop((scrollBackToEl.length > 0 ? scrollBackToEl : false), -40);
                    } else {
                        util.scrollElToTop((scrollBackToEl.length > 0 ? scrollBackToEl : false), -100);
                    }
                }
            }, this), 50);

            if (this.config.rightPanelLayout) {
                setTimeout($.proxy(function () {
                    $('#judgesDetail').empty().hide();
                }, this), 1000);
            }

        },

        // Staying on the right side and swapping content
        showRightSide: function (group) {

            $.each(this.leftWaypoints, function () {
                this.disable();
            });

            this.resetToggleBar(group, true);
            this.mobileToggleBar();

            if (this.config.rightPanelLayout) {
                var groupData = null;
                $.each(this.templateData['judgeGroups'], function (i, d) {
                    if (d.name == group) {
                        groupData = d.records;
                    }
                });
                $('#judgesDetail').html(templates.judgeDetailsModal({
                    records: $.map(groupData, function (rec) {
                        return {
                            id: rec.id,
                            h3: rec.name,
                            h4: rec.shortDescription,
                            span: rec.date,
                            type: rec.type,
                            image: rec.image,
                            quote: rec.quote,
                            content: rec.longDescription
                        }
                    })
                }));

                $('body').addClass('locked').trigger('pagenavupdate');
                $('#judgesDetail').show();
                $('#judgesDetail').find('.icon-close').on('click', $.proxy(function (e) {
                    e.preventDefault();
                    if (!$('.event-rsvp').hasClass('shrink')) {
                        $('.event-rsvp').removeClass('overlay-open');
                    }
                    $('#judgesDetail').find('.tplWrapper').removeClass('open');
                    this.showLeftSide(group);
                    $('.navbarWrap').removeClass('hidden');                    
                }, this));

                setTimeout(function () {
                    $('#judgesDetail').find('.tplWrapper').addClass('open');
                }, 25);

                // Fallback alignment methods
                // This is disabled per config defaults
                if (this.config.useFallbackMethods) {
                    setTimeout($.proxy(function () {
                        this.alignRightScroll();
                    }, this), 4000);
                }

            } else {
                // Hide the other sections, and show the new active section
                $('#judgesDetail .judgeInfoGroup').hide();
                $('#judgesDetail .judgeInfoGroup[data-judgegroup="' + group + '"]').show();

                // Scroll to and refresh
                $('html,body').animate({
                    scrollTop: -40
                }, 500, 'swing');

                //$('#judgesDetail .nav .currentCount').text(1);
                this.refreshAnim();
                setTimeout(function () {
                    $('body').trigger('pagenavupdate');
                }, 600);

            }

            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');

        },

        mobileToggleBar: function () {
            $('.judgeTypeBar span').each(function () {
                var w = $(window).width();
                if (w < 768) {
                    //$('.judgeTypeBar span').each(function(){
                    var text = $(this).text();
                    var words = $(this).text().split(' ');
                    var html = '';
                    $.each(words, function () {
                        html += this.substring(0, 1);
                    });
                    $(this).html(html);
                    //});
                }
            });


            $('.nav .typeIcon').each(function () {
                var w = $(window).width();
                if (w < 768) {
                    //$('.judgeTypeBar span').each(function(){
                    var text = $(this).text();
                    var words = $(this).text().split(' ');
                    var html = '';
                    $.each(words, function () {
                        html += this.substring(0, 1);
                    });
                    $(this).html(html);
                    //});
                }
            });
        },

        // Refresh animation states
        refreshAnim: function () {
            Waypoint.refreshAll();
            if (skrollr.get()) {
                skrollr.get().refresh();
            }
        },

        alignRightScroll: function () {
            if ($('body').hasClass('judgeDetailOpen')) {
                var elIndex = (parseInt($('#judgesDetail .sideNav .currentCount').text()) - 1);

                $('#judgesDetail').find('.tplWrapper').css('overflow', 'scroll');

                util.scrollElToTop($('*[data-pagenav="true"]').eq(elIndex), 0, 1);

                setTimeout($.proxy(function () {
                    $('#judgesDetail').find('.tplWrapper').css('overflow', 'hidden');
                }, this), 200);

            }
        }
    }
});
