define('modules/loadingscreen/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery", "jquery.mousewheel.min", "jquery.jscrollpane.min"], function (module, config, $, util, templates) {
    return new module({
        name: 'loadingscreen',
        selectors: '.loadingScreenWrap',
        remoteData: [],

        config: {},

        initialize: function (data) {
            this.$container = $(this.getSelectors());
            this.data = (data || this.data);
            this.renderTemplates();
        },

        onRender: function () {

        },

        methods: {
            renderTemplates: function () {
                this.$container.append(templates.loadingscreen());
                this.handleLoading();
            },

            handleLoading: function () {


                var loaderWidth = 100,
                    perfData = window.performance.timing,
                    // The PerformanceTiming interface represents timing-related performance information for the given page.
                    estimatedTime = -(perfData.loadEventStart - perfData.navigationStart),
                    //estTime = perfData.loadEventEnd;
                    time = parseInt(( estimatedTime / 1000) % 60) * 100;


                // handling for a better ux to see the loading screen
                if (time < 3000) {
                    time = 3000;
                }
                else {
                    // just use the estimate
                }

                var module = this;
                // Loadbar Animation
                $(".custom-loader-element-loader-bar-inner").animate({
                    width: loaderWidth + "%"
                }, time, function () {
                    setTimeout(function () {
                         //$('.custom-loader-element').addClass('load-finished');
                        $('.custom-loader-element-loader-bar').fadeOut();
                        $('.overlay-btn').addClass('loaded');
                        // module.fireLoadingAnimations();
                    }, 700);
                });

                $(window).bind("load", function() {
                    //$('.overlay-btn').addClass('loaded');
                    //$('.custom-loader-element').addClass('load-finished');
                    //module.fireLoadingAnimations();
                });

                $('.overlay-btn').on("click",function(){
                    setTimeout(function(){
                        $('body').removeClass('locked');
                        $('.loadingScreenWrap').addClass('load-finished');
                        $('.custom-loader-element').addClass('load-finished');
                        module.fireLoadingAnimations();
                    }, 500);

                });


            },

            fireLoadingAnimations: function () {
                var wow = new WOW({mobile: true, live: true});

                $('.loading-to-animate').addClass('fire-animation');

                setTimeout(function () {
                    $('.home-topcard-to-animate p').each(function (index) {
                        var $this = $(this);
                        setTimeout(function () {
                             $this.addClass('show-p')
                        }, 100 * index);
                    });
                    wow.init();
                }, 2500);


            }
        }
    });
});

